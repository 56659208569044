import {
  INIT_PATH,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_REFRESH_SUCCESS,
  SIGNOUT_USER,
  GET_USER_DETAILS_SUCCESS,
  AUTH_OPEN_DIALOG,
  AUTH_CLOSE_DIALOG,
  AUTH_CANCEL_LOADING,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  initPath: '/teams',
  loader: false,
  accessToken: '',
  refreshToken: localStorage.getItem('refreshToken'),
  user: {},
  dialogVisible: false,
  dialogLoading: false,
  loginEmail: '',
  loginPassword: '',
  newPassword: '',
  dialogType: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PATH: {
      return {
        ...state,
        initPath: action.payload
      };
    }
    case SIGNIN_USER_SUCCESS:
    case SIGNIN_USER_REFRESH_SUCCESS: {
      return {
        ...state,
        loader: false,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken
      };
    }
    case GET_USER_DETAILS_SUCCESS: {
      return {
        ...state,
        user: action.payload
      };
    }
    case SIGNOUT_USER: {
      return INIT_STATE;
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        loader: false
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    case AUTH_OPEN_DIALOG: {
      return {
        ...state,
        dialogVisible: true,
        loginEmail: action.payload.email,
        loginPassword: action.payload.password,
        dialogType: action.payload.dialogType
      };
    }
    case AUTH_CLOSE_DIALOG: {
      return {
        ...state,
        dialogVisible: false,
        dialogLoading: false
      };
    }
    case AUTH_CANCEL_LOADING: {
      return {
        ...state,
        dialogLoading: false
      };
    }
    case CHANGE_PASSWORD: {
      return {
        ...state,
        dialogLoading: true
      };
    }
    case FORGOT_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        dialogLoading: false,
        dialogVisible: false,
        newPassword: action.payload
      };
    }
    case FORGOT_PASSWORD: {
      return {
        ...state,
        dialogLoading: true
      };
    }
    default:
      return state;
  }
};
