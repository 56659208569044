import {
  GET_USER_PAYMENTS,
  GET_USER_PAYMENTS_SUCCESS,
  GET_PAYMENT_DETAIL,
  GET_PAYMENT_DETAIL_SUCCESS,
} from 'constants/ActionTypes';

export const getUserPayments = () => {
  return {
    type: GET_USER_PAYMENTS,
  };
};

export const getUserPaymentsSuccess = payments => {
  return {
    type: GET_USER_PAYMENTS_SUCCESS,
    payload: payments,
  };
};

export const getPaymentDetail = paymentId => {
  return {
    type: GET_PAYMENT_DETAIL,
    payload: { paymentId },
  };
};

export const getPaymentDetailSuccess = payment => {
  return {
    type: GET_PAYMENT_DETAIL_SUCCESS,
    payload: payment,
  };
};
