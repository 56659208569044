import {
  ADMIN_OPEN_DIALOG,
  ADMIN_CLOSE_DIALOG,
  ADMIN_CANCEL_LOADING,
  ADMIN_GET_ALL_USERS,
  ADMIN_GET_ALL_USERS_SUCCESS,
  ADMIN_CREATE_USER,
  ADMIN_CREATE_USER_SUCCESS,
  ADMIN_EDIT_USER,
  ADMIN_GET_ALL_TEAMS,
  ADMIN_GET_ALL_TEAMS_SUCCESS,
  ADMIN_CREATE_TEAM,
  ADMIN_EDIT_TEAM,
  ADMIN_GET_ALL_TARIFFS,
  ADMIN_GET_ALL_TARIFFS_SUCCESS,
  ADMIN_CREATE_TARIFF,
  ADMIN_EDIT_TARIFF,
  ADMIN_GET_ALL_DEVICES,
  ADMIN_GET_ALL_DEVICES_SUCCESS,
  ADMIN_GET_UNUSED_DEVICES_SUCCESS,
  ADMIN_GET_DEVICE_PRODUCT_TYPES_SUCCESS,
  ADMIN_CREATE_DEVICE,
  ADMIN_EDIT_DEVICE,
  ADMIN_GET_ALL_LICENCES,
  ADMIN_GET_ALL_LICENCES_SUCCESS,
  ADMIN_CREATE_LICENCE,
  ADMIN_EDIT_LICENCE,
  ADMIN_RENEW_LICENCE,
  INVITE_TEAM_MEMBER,
  SIGNOUT_USER,
  ADMIN_GET_ALL_PAYMENTS,
  ADMIN_GET_ALL_PAYMENTS_SUCCESS,
  ADMIN_GET_LICENCES,
  ADMIN_GET_LICENCES_SUCCESS
} from 'constants/ActionTypes';

const INIT_STATE = {
  loading: false,
  dialogVisible: false,
  dialogType: '',
  requestedEntity: null,
  createdUserInfo: null,
  users: [],
  teams: [],
  tariffs: [],
  devices: [],
  unusedDevices: [],
  deviceProductTypes: [],
  licences: [],
  license: {},
  payments:[]
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADMIN_OPEN_DIALOG: {
      return {
        ...state,
        dialogVisible: true,
        dialogType: action.payload.type,
        requestedEntity: action.payload.requestedEntity,
        createdUserInfo: null,
      };
    }
    case ADMIN_CLOSE_DIALOG: {
      return {
        ...state,
        dialogVisible: false,
        loading: false,
        requestedEntity: null,
        createdUserInfo: null,
      };
    }
    case ADMIN_CANCEL_LOADING: {
      return {
        ...state,
        loading: false,
      };
    }
    // Users
    case INVITE_TEAM_MEMBER:
    case ADMIN_GET_ALL_USERS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_GET_ALL_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload,
        loading: false,
      };
    }
    case ADMIN_CREATE_USER: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_CREATE_USER_SUCCESS: {
      return {
        ...state,
        createdUserInfo: action.payload,
        loading: false,
      };
    }
    case ADMIN_EDIT_USER: {
      return {
        ...state,
        requestedEntity: action.payload,
        loading: true,
      };
    }

    //payments

    case ADMIN_GET_ALL_PAYMENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_GET_ALL_PAYMENTS_SUCCESS: {
      return {
        ...state,
        payments: action.payload,
        loading: false,
      };
    }
    // Teams
    case ADMIN_GET_ALL_TEAMS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_GET_ALL_TEAMS_SUCCESS: {
      return {
        ...state,
        teams: action.payload,
        loading: false,
      };
    }
    
    case ADMIN_CREATE_TEAM: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_EDIT_TEAM: {
      return {
        ...state,
        requestedEntity: action.payload,
        loading: true,
      };
    }
    // Tariffs
    case ADMIN_GET_ALL_TARIFFS: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_GET_ALL_TARIFFS_SUCCESS: {
      return {
        ...state,
        tariffs: action.payload,
        loading: false,
      };
    }
    case ADMIN_CREATE_TARIFF: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_EDIT_TARIFF: {
      return {
        ...state,
        requestedEntity: action.payload,
        loading: true,
      };
    }
    // Devices
    case ADMIN_GET_ALL_DEVICES: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_GET_ALL_DEVICES_SUCCESS: {
      return {
        ...state,
        devices: action.payload,
        loading: false,
      };
    }
    case ADMIN_GET_UNUSED_DEVICES_SUCCESS: {
      return {
        ...state,
        unusedDevices: action.payload,
      };
    }
    case ADMIN_GET_DEVICE_PRODUCT_TYPES_SUCCESS: {
      return {
        ...state,
        deviceProductTypes: action.payload,
      };
    }
    case ADMIN_CREATE_DEVICE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_EDIT_DEVICE: {
      return {
        ...state,
        requestedEntity: action.payload,
        loading: true,
      };
    }
    // Licences
    case ADMIN_GET_ALL_LICENCES: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_GET_ALL_LICENCES_SUCCESS: {
      return {
        ...state,
        licences: action.payload,
        loading: false,
      };
    }

    case ADMIN_GET_LICENCES: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_GET_LICENCES_SUCCESS: {
      return {
        ...state,
        license: action.payload,
        loading: false,
      };
    }
    case ADMIN_CREATE_LICENCE: {
      return {
        ...state,
        loading: true,
      };
    }
    case ADMIN_EDIT_LICENCE:
    case ADMIN_RENEW_LICENCE: {
      return {
        ...state,
        requestedEntity: action.payload,
        loading: true,
      };
    }
    case SIGNOUT_USER: {
      return INIT_STATE;
    }
    default:
      return state;
  }
};
