import { authBase, apiBuilder, getAuthHeaders } from './index';


export const getUserTeamsApi = () =>
  apiBuilder(`${authBase}/teams`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });

export const getTeamDetailApi = teamId =>
  apiBuilder(`${authBase}/teams/${teamId}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });

export const getTeamUsersApi = teamId =>
  apiBuilder(`${authBase}/teams/${teamId}/users`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });

export const getTeamInvitesApi = teamId =>
  apiBuilder(`${authBase}/teams/${teamId}/invite`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });

export const getAllTeamsApi = () =>
  apiBuilder(`${authBase}/teams/all`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
  export const getAllExistTeamsApi = () =>
  apiBuilder(`${authBase}/teams/allExists`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
export const createTeamApi = team =>
  apiBuilder(`${authBase}/teams`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(team),
  });

export const inviteTeamMemberApi = (teamId, email) =>
  apiBuilder(`${authBase}/teams/${teamId}/invite?inviteeLogin=${email}`, {
    method: 'POST',
    headers: getAuthHeaders(),
  });

export const editTeamApi = team =>
  apiBuilder(`${authBase}/teams/${team.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(team),
  });

export const deleteTeamApi = teamId =>
  apiBuilder(`${authBase}/teams/${teamId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
  export const restoreTeamApi = teamId =>
  apiBuilder(`${authBase}/teams/Restore/${teamId}`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
export const cancelInviteApi = inviteId =>
  apiBuilder(`${authBase}/teams/invite/${inviteId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });

export const kickUserFromTeamApi = (teamId, userId) =>
  apiBuilder(`${authBase}/teams/${teamId}/user/${userId}`, {
    method: 'DELETE',
    headers: getAuthHeaders(),
  });
