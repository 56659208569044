import {
  GET_USER_TEAMS,
  GET_USER_TEAMS_SUCCESS,
  GET_TEAM_DETAIL,
  GET_TEAM_DETAIL_SUCCESS,
  GET_TEAM_DEVICES,
  GET_TEAM_DEVICES_SUCCESS,
  GET_DEVICE_BATCHES,
  GET_DEVICE_BATCHES_SUCCESS,
  GET_BATCH_READINGS,
  GET_BATCH_READINGS_SUCCESS,
  SET_CO2_GAUGE,
  SET_PRESSURE_GAUGE,
  SET_TEMPERATURE_GAUGE,
  SET_TEMPERATURE_F_GAUGE,
  GET_TEAM_USERS,
  GET_TEAM_USERS_SUCCESS,
  GET_TEAM_INVITES,
  GET_TEAM_INVITES_SUCCESS,
  INVITE_TEAM_MEMBER,
  CANCEL_INVITE,
  KICK_USER_FROM_TEAM,
  SET_INTERVAL,
  CLEAR_INTERVAL,
  GET_CHECK_LICENSE,
  GET_CHECK_LICENSE_SUCCESS
} from 'constants/ActionTypes';

export const getUserTeams = () => {
  return {
    type: GET_USER_TEAMS
  };
};

export const getUserTeamsSuccess = teams => {
  return {
    type: GET_USER_TEAMS_SUCCESS,
    payload: teams
  };
};

export const getCheckLicense = () => {
  return {
    type: GET_CHECK_LICENSE
  };
};

export const getCheckLicenseSuccess = licenses => {
  return {
    type: GET_CHECK_LICENSE_SUCCESS,
    payload: licenses
  };
};

export const getTeamDetail = (teamId, initDevice, initBatch) => {
  return {
    type: GET_TEAM_DETAIL,
    payload: { teamId, initDevice, initBatch }
  };
};

export const getTeamDetailSuccess = team => {
  return {
    type: GET_TEAM_DETAIL_SUCCESS,
    payload: team
  };
};

export const getTeamDevices = teamId => {
  return {
    type: GET_TEAM_DEVICES,
    payload: teamId
  };
};

export const getTeamDevicesSuccess = devices => {
  return {
    type: GET_TEAM_DEVICES_SUCCESS,
    payload: devices
  };
};

export const getDeviceBatches = device => {
  return {
    type: GET_DEVICE_BATCHES,
    payload: device
  };
};

export const getDeviceBatchesSuccess = batches => {
  return {
    type: GET_DEVICE_BATCHES_SUCCESS,
    payload: batches
  };
};

export const getBatchReadings = batchId => {
  return {
    type: GET_BATCH_READINGS,
    payload: batchId
  };
};

export const getBatchReadingsSuccess = (all, last, comments) => {
  return {
    type: GET_BATCH_READINGS_SUCCESS,
    payload: { all, last, comments }
  };
};

export const setCo2Gauge = gauge => {
  return {
    type: SET_CO2_GAUGE,
    payload: gauge
  };
};

export const setPressureGauge = gauge => {
  return {
    type: SET_PRESSURE_GAUGE,
    payload: gauge
  };
};

export const setTemperatureGauge = gauge => {
  return {
    type: SET_TEMPERATURE_GAUGE,
    payload: gauge
  };
};

export const setTemperatureFGauge = gauge => {
  return {
    type: SET_TEMPERATURE_F_GAUGE,
    payload: gauge
  };
};

export const getTeamUsers = teamId => {
  return {
    type: GET_TEAM_USERS,
    payload: teamId
  };
};

export const getTeamUsersSuccess = users => {
  return {
    type: GET_TEAM_USERS_SUCCESS,
    payload: users
  };
};

export const getTeamInvites = teamId => {
  return {
    type: GET_TEAM_INVITES,
    payload: teamId
  };
};

export const getTeamInvitesSuccess = invites => {
  return {
    type: GET_TEAM_INVITES_SUCCESS,
    payload: invites
  };
};

export const inviteTeamMember = (teamId, email) => {
  return {
    type: INVITE_TEAM_MEMBER,
    payload: { teamId, email }
  };
};

export const cancelInvite = (teamId, inviteId) => {
  return {
    type: CANCEL_INVITE,
    payload: { teamId, inviteId }
  };
};

export const kickUserFromTeam = (teamId, userId) => {
  return {
    type: KICK_USER_FROM_TEAM,
    payload: { teamId, userId }
  };
};

export const setIntervalAction = interval => {
  return {
    type: SET_INTERVAL,
    payload: interval
  };
};

export const clearIntervalAction = () => {
  return {
    type: CLEAR_INTERVAL
  };
};
