import { coreBase, apiBuilder, getAuthHeaders } from './index';

export const getDeviceBatchesApi = deviceId =>
  apiBuilder(`${coreBase}/batch?deviceId=${deviceId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getBatchReadingsAllApi = batchId =>
  apiBuilder(`${coreBase}/batch/${batchId}/readings/all`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getBatchReadingsLastApi = batchId =>
  apiBuilder(`${coreBase}/batch/${batchId}/readings/last`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const newComment = (batchId, comment) =>
  apiBuilder(`${coreBase}/batch/${batchId}/comment`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(comment)
  });

export const deleteComment = commentId =>
  apiBuilder(`${coreBase}/batch/${commentId}/comment`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
