import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import Teams from './Teams';
import Payments from './Payments';

import Admin from './Admin';

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  teams: Teams,
  admin: Admin,
  payments:Payments
});

export default reducers;
