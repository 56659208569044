import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  ADMIN_GET_ALL_USERS,
  ADMIN_CREATE_USER,
  ADMIN_EDIT_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_ALL_TEAMS,
  ADMIN_CREATE_TEAM,
  ADMIN_EDIT_TEAM,
  ADMIN_DELETE_TEAM,
  ADMIN_GET_ALL_TARIFFS,
  ADMIN_CREATE_TARIFF,
  ADMIN_EDIT_TARIFF,
  ADMIN_DELETE_TARIFF,
  ADMIN_GET_ALL_DEVICES,
  ADMIN_GET_UNUSED_DEVICES,
  ADMIN_GET_DEVICE_PRODUCT_TYPES,
  ADMIN_CREATE_DEVICE,
  ADMIN_EDIT_DEVICE,
  ADMIN_DELETE_DEVICE,
  ADMIN_GET_ALL_LICENCES,
  ADMIN_GET_LICENCES,
  ADMIN_CREATE_LICENCE,
  ADMIN_EDIT_LICENCE,
  ADMIN_RENEW_LICENCE,
  ADMIN_DELETE_LICENCE,
  ADMIN_GET_ALL_PAYMENTS,
  ADMIN_RESTORE_LICENCE,ADMIN_GET_ALL_EXIST
} from 'constants/ActionTypes';
import {
  adminCancelLoading,
  adminCloseDialog,
  adminCreateUserSuccess,
  adminGetAllUsersSuccess,
  adminGetAllUsers,
  adminGetAllTeamsSuccess,
  adminGetAllTeams,
  adminGetAllTariffsSuccess,
  adminGetAllTariffs,
  adminGetAllDevicesSuccess,
  adminGetAllDevices,
  adminGetUnusedDevicesSuccess,
  adminGetDeviceProductTypesSuccess,
  adminGetAllLicencesSuccess,
  adminGetLicencesSuccess,
  adminGetAllLicences,
  adminGetAllPayments,
  adminGetAllPaymentsSuccess
} from '../actions/Admin';
import { getTeamDetail } from '../actions/Teams';
import { showAuthMessage } from '../actions/Auth';
import {
  getAllUsersApi,
  createUserApi,
  editUserApi,
  deleteUserApi,
  getAllTeamsApi,
  createTeamApi,
  editTeamApi,
  deleteTeamApi,
  getAllTariffsApi,
  createTariffApi,
  editTariffApi,
  deleteTariffApi,
  getAllDevicesApi,
  getUnusedDevicesApi,
  getDeviceProductTypesApi,
  createDeviceApi,
  editDeviceApi,
  deleteDeviceApi,
  getAllLicencesApi,
  getLicencesApi,
  createLicenceApi,
  editLicenceApi,
  renewLicenceApi,
  deleteLicenceApi,
  getAllPaymentsApi,
  restoreTeamApi,
  getAllExistTeamsApi
} from '../../api';


// Users
function* adminGetAllUsersHandler() {
  try {
    const res = yield call(getAllUsersApi);
    yield put(adminGetAllUsersSuccess(res.users));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminCreateUserHandler({ payload }) {
  try {
    const res = yield call(createUserApi, payload);
    yield all([put(adminCreateUserSuccess(res)), put(adminGetAllUsers())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminEditUserHandler({ payload }) {
  try {
    yield call(editUserApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllUsers())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminDeleteUserHandler({ payload }) {
  try {
    const res = yield call(deleteUserApi, payload);
    yield put(adminGetAllUsers(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

export function* adminGetAllUsersSaga() {
  yield takeEvery(ADMIN_GET_ALL_USERS, adminGetAllUsersHandler);
}

export function* adminCreateUserSaga() {
  yield takeEvery(ADMIN_CREATE_USER, adminCreateUserHandler);
}

export function* adminEditUserSaga() {
  yield takeEvery(ADMIN_EDIT_USER, adminEditUserHandler);
}

export function* adminDeleteUserSaga() {
  yield takeEvery(ADMIN_DELETE_USER, adminDeleteUserHandler);
}

//PAYMENYS

function* adminGetAllPaymentsHandler() {
  try {
    const res = yield call(getAllPaymentsApi);
    yield put(adminGetAllPaymentsSuccess(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}


export function* adminGetAllPaymentsSaga() {
  yield takeEvery(ADMIN_GET_ALL_PAYMENTS, adminGetAllPaymentsHandler);
}



// Teams
function* adminGetAllTeamsHandler() {
  try {
    const res = yield call(getAllTeamsApi);
    yield put(adminGetAllTeamsSuccess(res.teams));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminCreateTeamHandler({ payload }) {
  try {
    yield call(createTeamApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllTeams())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminEditTeamHandler({ payload }) {
  try {
    yield call(editTeamApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllTeams()), put(getTeamDetail(payload.id))]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminRestoreTeamHandler({ payload }) {
  try {
    yield call(restoreTeamApi, payload);
    // yield all([put(adminCloseDialog()), put(adminGetAllTeams()), put(getTeamDetail(payload.id))]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}
function* adminAllListHandler() {
  try {
    const res = yield call(getAllExistTeamsApi);
    yield put(adminGetAllTeamsSuccess(res.teams));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}
function* adminDeleteTeamHandler({ payload }) {
  try {
    const res = yield call(deleteTeamApi, payload);
    yield put(adminGetAllTeams(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

export function* adminGetAllTeamsSaga() {
  yield takeEvery(ADMIN_GET_ALL_TEAMS, adminGetAllTeamsHandler);
}

export function* adminCreateTeamSaga() {
  yield takeEvery(ADMIN_CREATE_TEAM, adminCreateTeamHandler);
}

export function* adminEditTeamSaga() {
  yield takeEvery(ADMIN_EDIT_TEAM, adminEditTeamHandler);
}

export function* adminDeleteTeamSaga() {
  yield takeEvery(ADMIN_DELETE_TEAM, adminDeleteTeamHandler);
}
export function* adminRestoreTeamSaga() {
  yield takeEvery(ADMIN_RESTORE_LICENCE, adminRestoreTeamHandler);
}
export function* adminGetAllExistSaga() {
  yield takeEvery(ADMIN_GET_ALL_EXIST, adminAllListHandler);
}
// Tariffs
function* adminGetAllTariffsHandler() {
  try {
    const res = yield call(getAllTariffsApi);
    yield put(adminGetAllTariffsSuccess(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminCreateTariffHandler({ payload }) {
  try {
    yield call(createTariffApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllTariffs())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminEditTariffHandler({ payload }) {
  try {
    yield call(editTariffApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllTariffs())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminDeleteTariffHandler({ payload }) {
  try {
    const res = yield call(deleteTariffApi, payload);
    yield put(adminGetAllTariffs(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

export function* adminGetAllTariffsSaga() {
  yield takeEvery(ADMIN_GET_ALL_TARIFFS, adminGetAllTariffsHandler);
}

export function* adminCreateTariffSaga() {
  yield takeEvery(ADMIN_CREATE_TARIFF, adminCreateTariffHandler);
}

export function* adminEditTariffSaga() {
  yield takeEvery(ADMIN_EDIT_TARIFF, adminEditTariffHandler);
}

export function* adminDeleteTariffSaga() {
  yield takeEvery(ADMIN_DELETE_TARIFF, adminDeleteTariffHandler);
}

// Devices
function* adminGetAllDevicesHandler() {
  try {
    const res = yield call(getAllDevicesApi);
    yield put(adminGetAllDevicesSuccess(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminGetUnusedDevicesHandler() {
  try {
    const res = yield call(getUnusedDevicesApi);
    yield put(adminGetUnusedDevicesSuccess(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminGetDeviceProductTypesHandler() {
  try {
    const res = yield call(getDeviceProductTypesApi);
    yield put(adminGetDeviceProductTypesSuccess(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminCreateDeviceHandler({ payload }) {
  try {
    yield call(createDeviceApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllDevices())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminEditDeviceHandler({ payload }) {
  try {
    yield call(editDeviceApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllDevices())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminDeleteDeviceHandler({ payload }) {
  try {
    const res = yield call(deleteDeviceApi, payload);
    yield put(adminGetAllDevices(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

export function* adminGetAllDevicesSaga() {
  yield takeEvery(ADMIN_GET_ALL_DEVICES, adminGetAllDevicesHandler);
}

export function* adminGetUnusedDevicesSaga() {
  yield takeEvery(ADMIN_GET_UNUSED_DEVICES, adminGetUnusedDevicesHandler);
}

export function* adminGetDeviceProductTypesSaga() {
  yield takeEvery(ADMIN_GET_DEVICE_PRODUCT_TYPES, adminGetDeviceProductTypesHandler);
}

export function* adminCreateDeviceSaga() {
  yield takeEvery(ADMIN_CREATE_DEVICE, adminCreateDeviceHandler);
}

export function* adminEditDeviceSaga() {
  yield takeEvery(ADMIN_EDIT_DEVICE, adminEditDeviceHandler);
}

export function* adminDeleteDeviceSaga() {
  yield takeEvery(ADMIN_DELETE_DEVICE, adminDeleteDeviceHandler);
}

// Licences
function* adminGetAllLicencesHandler() {
  try {
    const res = yield call(getAllLicencesApi);
    yield put(adminGetAllLicencesSuccess(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}
function* adminGetLicencesHandler({ payload }) {
  // try {
  //   const res = yield call(getLicencesApi,payload);
  //   yield put(adminGetLicencesSuccess(res));
  // } catch (err) {
  //   yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  // }

  const { paymentId } = payload;
  try {
    const res = yield call(getLicencesApi, paymentId);
    yield put(adminGetLicencesSuccess(res));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}
function* adminCreateLicenceHandler({ payload }) {
  try {
    const res = yield call(createLicenceApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllLicences())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminEditLicenceHandler({ payload }) {
  try {
    yield call(editLicenceApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllLicences())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminRenewLicenceHandler({ payload }) {
  try {
    yield call(renewLicenceApi, payload);
    yield all([put(adminCloseDialog()), put(adminGetAllLicences())]);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

function* adminDeleteLicenceHandler({ payload }) {
  try {
    const res = yield call(deleteLicenceApi, payload);
    yield put(adminGetAllLicences(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(adminCancelLoading())]);
  }
}

export function* adminGetAllLicencesSaga() {
  yield takeEvery(ADMIN_GET_ALL_LICENCES, adminGetAllLicencesHandler);
}
export function* adminGetLicencesSaga() {
  yield takeEvery(ADMIN_GET_LICENCES, adminGetLicencesHandler);
}
export function* adminCreateLicenceSaga() {
  yield takeEvery(ADMIN_CREATE_LICENCE, adminCreateLicenceHandler);
}

export function* adminEditLicenceSaga() {
  yield takeEvery(ADMIN_EDIT_LICENCE, adminEditLicenceHandler);
}

export function* adminRenewLicenceSaga() {
  yield takeEvery(ADMIN_RENEW_LICENCE, adminRenewLicenceHandler);
}

export function* adminDeleteLicenceSaga() {
  yield takeEvery(ADMIN_DELETE_LICENCE, adminDeleteLicenceHandler);
}

export default function* rootSaga() {
  yield all([
    fork(adminGetAllUsersSaga),
    fork(adminCreateUserSaga),
    fork(adminEditUserSaga),
    fork(adminDeleteUserSaga),
    fork(adminGetAllTeamsSaga),
    fork(adminCreateTeamSaga),
    fork(adminEditTeamSaga),
    fork(adminDeleteTeamSaga),
    fork(adminGetAllTariffsSaga),
    fork(adminCreateTariffSaga),
    fork(adminEditTariffSaga),
    fork(adminDeleteTariffSaga),
    fork(adminGetAllDevicesSaga),
    fork(adminGetUnusedDevicesSaga),
    fork(adminGetDeviceProductTypesSaga),
    fork(adminCreateDeviceSaga),
    fork(adminEditDeviceSaga),
    fork(adminDeleteDeviceSaga),
    fork(adminGetAllLicencesSaga),
    fork(adminGetLicencesSaga),
    fork(adminCreateLicenceSaga),
    fork(adminEditLicenceSaga),
    fork(adminRenewLicenceSaga),
    fork(adminDeleteLicenceSaga),
    fork(adminGetAllPaymentsSaga),
    fork(adminRestoreTeamSaga),
    fork(adminGetAllExistSaga)
  ]);
}
