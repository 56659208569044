import { coreBase, apiBuilder, getAuthHeaders } from './index';

export const getAllTariffsApi = () =>
  apiBuilder(`${coreBase}/tariff`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const createTariffApi = tariff =>
  apiBuilder(`${coreBase}/tariff`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(tariff)
  });

export const editTariffApi = tariff =>
  apiBuilder(`${coreBase}/tariff/${tariff.uuid}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(tariff)
  });

export const deleteTariffApi = tariffId =>
  apiBuilder(`${coreBase}/tariff/${tariffId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
