import { coreBase, apiBuilder, getAuthHeaders } from './index';

export const getCheckLicensesApi = () =>
  apiBuilder(`${coreBase}/licence/check`, {
    method: 'GET',
    headers: getAuthHeaders(),
  });
export const getAllLicencesApi = () =>
  apiBuilder(`${coreBase}/licence`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
  export const getLicencesApi = paymentId =>
  apiBuilder(`${coreBase}/licence/bypayment/${paymentId}`, {
    method: 'GET',
    headers: getAuthHeaders()
  });
export const createLicenceApi = licence =>
  apiBuilder(`${coreBase}/licence`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify(licence)
  });

export const editLicenceApi = licence =>
  apiBuilder(`${coreBase}/licence/${licence.uuid}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(licence)
  });

export const renewLicenceApi = licence =>
  apiBuilder(`${coreBase}/licence/${licence.uuid}/renew`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify(licence)
  });

export const deleteLicenceApi = licenceId =>
  apiBuilder(`${coreBase}/licence/${licenceId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
