import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { GET_USER_PAYMENTS, GET_PAYMENT_DETAIL } from 'constants/ActionTypes';
import { getUserPaymentsSuccess, getPaymentDetailSuccess } from '../actions/Payments';
import { showAuthMessage } from '../actions/Auth';
import { getUserPaymentsApi, getPaymentDetailApi } from '../../api';

function* handleGetUserPayments() {
  try {
    const res = yield call(getUserPaymentsApi);
    yield put(getUserPaymentsSuccess(res));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* handleGetPaymentDetail({ payload }) {
  const { paymentId } = payload;
  try {
    const res = yield call(getPaymentDetailApi, paymentId);
    yield put(getPaymentDetailSuccess(res));
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

export function* getUserPaymentsSaga() {
  yield takeEvery(GET_USER_PAYMENTS, handleGetUserPayments);
}

export function* getPaymentDetailSaga() {
  yield takeEvery(GET_PAYMENT_DETAIL, handleGetPaymentDetail);
}

export default function* rootSaga() {
  yield all([fork(getUserPaymentsSaga), fork(getPaymentDetailSaga)]);
}
