import {
  ADMIN_OPEN_DIALOG,
  ADMIN_CLOSE_DIALOG,
  ADMIN_CANCEL_LOADING,
  ADMIN_GET_ALL_USERS,
  ADMIN_GET_ALL_USERS_SUCCESS,
  ADMIN_CREATE_USER,
  ADMIN_CREATE_USER_SUCCESS,
  ADMIN_EDIT_USER,
  ADMIN_DELETE_USER,
  ADMIN_GET_ALL_TEAMS,
  ADMIN_GET_ALL_TEAMS_SUCCESS,
  ADMIN_CREATE_TEAM,
  ADMIN_EDIT_TEAM,
  ADMIN_DELETE_TEAM,
  ADMIN_GET_ALL_TARIFFS,
  ADMIN_GET_ALL_TARIFFS_SUCCESS,
  ADMIN_CREATE_TARIFF,
  ADMIN_EDIT_TARIFF,
  ADMIN_DELETE_TARIFF,
  ADMIN_GET_ALL_DEVICES,
  ADMIN_GET_ALL_DEVICES_SUCCESS,
  ADMIN_GET_UNUSED_DEVICES,
  ADMIN_GET_UNUSED_DEVICES_SUCCESS,
  ADMIN_GET_DEVICE_PRODUCT_TYPES,
  ADMIN_GET_DEVICE_PRODUCT_TYPES_SUCCESS,
  ADMIN_CREATE_DEVICE,
  ADMIN_EDIT_DEVICE,
  ADMIN_DELETE_DEVICE,
  ADMIN_GET_ALL_LICENCES,
  ADMIN_GET_ALL_LICENCES_SUCCESS,
  ADMIN_GET_LICENCES_SUCCESS,
  ADMIN_CREATE_LICENCE,
  ADMIN_EDIT_LICENCE,
  ADMIN_RENEW_LICENCE,
  ADMIN_DELETE_LICENCE,
  ADMIN_GET_ALL_PAYMENTS,
  ADMIN_GET_ALL_PAYMENTS_SUCCESS,
  ADMIN_RESTORE_LICENCE,
  ADMIN_GET_LICENCES
} from 'constants/ActionTypes';
import { ADMIN_GET_ALL_EXIST } from '../../constants/ActionTypes';

export const adminOpenDialog = (type, requestedEntity) => {
  return {
    type: ADMIN_OPEN_DIALOG,
    payload: { type, requestedEntity }
  };
};

export const adminCloseDialog = () => {
  return {
    type: ADMIN_CLOSE_DIALOG
  };
};

export const adminCancelLoading = () => {
  return {
    type: ADMIN_CANCEL_LOADING
  };
};

// Users
export const adminGetAllUsers = () => {
  return {
    type: ADMIN_GET_ALL_USERS
  };
};

export const adminGetAllUsersSuccess = users => {
  return {
    type: ADMIN_GET_ALL_USERS_SUCCESS,
    payload: users
  };
};

export const adminCreateUser = user => {
  return {
    type: ADMIN_CREATE_USER,
    payload: user
  };
};

export const adminCreateUserSuccess = info => {
  return {
    type: ADMIN_CREATE_USER_SUCCESS,
    payload: info
  };
};

export const adminEditUser = user => {
  return {
    type: ADMIN_EDIT_USER,
    payload: user
  };
};

export const adminDeleteUser = userId => {
  return {
    type: ADMIN_DELETE_USER,
    payload: userId
  };
};

// Teams
export const adminGetAllTeams = () => {
  return {
    type: ADMIN_GET_ALL_TEAMS
  };
};

export const adminGetAllTeamsSuccess = teams => {
  return {
    type: ADMIN_GET_ALL_TEAMS_SUCCESS,
    payload: teams
  };
};


export const adminGetAllPayments = () => {
  return {
    type: ADMIN_GET_ALL_PAYMENTS
  };
};

export const adminGetAllPaymentsSuccess = payments => {
  return {
    type: ADMIN_GET_ALL_PAYMENTS_SUCCESS,
    payload: payments
  };
};

export const adminCreateTeam = team => {
  return {
    type: ADMIN_CREATE_TEAM,
    payload: team
  };
};

export const adminEditTeam = team => {
  return {
    type: ADMIN_EDIT_TEAM,
    payload: team
  };
};

export const adminDeleteTeam = teamId => {
  return {
    type: ADMIN_DELETE_TEAM,
    payload: teamId
  };
};

// Tariffs
export const adminGetAllTariffs = () => {
  return {
    type: ADMIN_GET_ALL_TARIFFS
  };
};

export const adminGetAllTariffsSuccess = tariffs => {
  return {
    type: ADMIN_GET_ALL_TARIFFS_SUCCESS,
    payload: tariffs
  };
};

export const adminCreateTariff = tariff => {
  return {
    type: ADMIN_CREATE_TARIFF,
    payload: tariff
  };
};

export const adminEditTariff = tariff => {
  return {
    type: ADMIN_EDIT_TARIFF,
    payload: tariff
  };
};

export const adminDeleteTariff = tariffId => {
  return {
    type: ADMIN_DELETE_TARIFF,
    payload: tariffId
  };
};

// Devices
export const adminGetAllDevices = () => {
  return {
    type: ADMIN_GET_ALL_DEVICES
  };
};

export const adminGetAllDevicesSuccess = devices => {
  return {
    type: ADMIN_GET_ALL_DEVICES_SUCCESS,
    payload: devices
  };
};

export const adminGetUnusedDevices = () => {
  return {
    type: ADMIN_GET_UNUSED_DEVICES
  };
};

export const adminGetUnusedDevicesSuccess = devices => {
  return {
    type: ADMIN_GET_UNUSED_DEVICES_SUCCESS,
    payload: devices
  };
};

export const adminGetDeviceProductTypes = () => {
  return {
    type: ADMIN_GET_DEVICE_PRODUCT_TYPES
  };
};

export const adminGetDeviceProductTypesSuccess = devices => {
  return {
    type: ADMIN_GET_DEVICE_PRODUCT_TYPES_SUCCESS,
    payload: devices
  };
};

export const adminCreateDevice = device => {
  return {
    type: ADMIN_CREATE_DEVICE,
    payload: device
  };
};

export const adminEditDevice = device => {
  return {
    type: ADMIN_EDIT_DEVICE,
    payload: device
  };
};

export const adminDeleteDevice = deviceId => {
  return {
    type: ADMIN_DELETE_DEVICE,
    payload: deviceId
  };
};

// Licences
export const adminGetAllLicences = () => {
  return {
    type: ADMIN_GET_ALL_LICENCES
  };
};
export const adminGetLicences = paymentId => {
  return {
    type: ADMIN_GET_LICENCES,
    payload: { paymentId }
  };
  
};
// export const adminGetLicences = (paymentId) =>
//   apiBuilder(`${coreBase}/securepayment/admin/report?from=${from}&to=${to}`, {
//     method: 'GET',
//     headers: getAuthHeaders()
//   });
export const adminGetAllLicencesSuccess = licences => {
  return {
    type: ADMIN_GET_ALL_LICENCES_SUCCESS,
    payload: licences
  };
};
export const adminGetLicencesSuccess = license => {
  return {
    type: ADMIN_GET_LICENCES_SUCCESS,
    payload:  license 
  };
};
export const adminCreateLicence = licence => {
  return {
    type: ADMIN_CREATE_LICENCE,
    payload: licence
  };
};

export const adminEditLicence = licence => {
  return {
    type: ADMIN_EDIT_LICENCE,
    payload: licence
  };
};

export const adminRenewLicence = licence => {
  return {
    type: ADMIN_RENEW_LICENCE,
    payload: licence
  };
};

export const adminDeleteLicence = licenceId => {
  return {
    type: ADMIN_DELETE_LICENCE,
    payload: licenceId
  };
};
export const adminRestoreTeam = licenceId => {
  return {
    type: ADMIN_RESTORE_LICENCE,
    payload: licenceId
  };
};

export const adminGetAllExistTeams = () => {
  return {
    type: ADMIN_GET_ALL_EXIST
   
  };
};