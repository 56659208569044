import { store } from 'NextApp';
import { authBase, apiBuilder, getAuthHeaders } from './index';

export const getCurrentUserDetails = () =>
  apiBuilder(`${authBase}/users/me`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const getAllUsersApi = () =>
  apiBuilder(`${authBase}/users`, {
    method: 'GET',
    headers: getAuthHeaders()
  });

export const createUserApi = user =>
  apiBuilder(`${authBase}/users/user`, {
    method: 'POST',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...user, tenantId: store.getState().auth.user.tenant.id })
  });

export const editUserApi = user =>
  apiBuilder(`${authBase}/users/${user.id}`, {
    method: 'PUT',
    headers: getAuthHeaders(),
    body: JSON.stringify({ ...user, tenantId: store.getState().auth.user.tenant.id })
  });

export const deleteUserApi = userId =>
  apiBuilder(`${authBase}/users/${userId}`, {
    method: 'DELETE',
    headers: getAuthHeaders()
  });
