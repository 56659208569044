import React from 'react';
import { Route, Switch } from 'react-router-dom';

import asyncComponent from 'util/asyncComponent';
const App = ({ match }) => (

  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}auth`} component={asyncComponent(() => import('./auth'))} />
      <Route path={`${match.url}teams`} component={asyncComponent(() => import('./teams'))} />
      <Route path={`${match.url}payments`} component={asyncComponent(() => import('./payments'))} />
      <Route path={`${match.url}admin`} component={asyncComponent(() => import('./admin'))} />

    </Switch>
  </div>
);

export default App;
