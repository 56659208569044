import { authBase, headers, apiBuilder } from './index';

export const loginByEmail = (email, password) =>
  apiBuilder(`${authBase}/authentication/email`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ email, password, clientId: 'web' })
  });

export const loginByRefresh = refreshToken =>
  apiBuilder(`${authBase}/authentication/refreshtoken`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ refreshToken, clientId: 'web' })
  });

export const changePasswordApi = form =>
  apiBuilder(`${authBase}/password`, {
    method: 'PUT',
    headers,
    body: JSON.stringify(form)
  });

export const requestResetPasswordApi = loginEmail =>
  apiBuilder(`${authBase}/password/reset?login=${loginEmail}`, {
    method: 'GET',
    headers
  });

export const resetPasswordApi = form =>
  apiBuilder(`${authBase}/password/reset`, {
    method: 'POST',
    headers,
    body: JSON.stringify(form)
  });
