export const INIT = 'INIT';
export const INIT_PATH = 'INIT_PATH';

// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_REFRESH = 'SIGNIN_USER_REFRESH';
export const SIGNIN_USER_REFRESH_SUCCESS = 'SIGNIN_USER_REFRESH_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const GET_USER_DETAILS = 'GET_USER_DETAILS';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const REQUEST_FORGOT_PASSWORD = 'REQUEST_FORGOT_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

// Teams
export const GET_USER_TEAMS = 'GET_USER_TEAMS';
export const GET_USER_TEAMS_SUCCESS = 'GET_USER_TEAMS_SUCCESS';
export const GET_CHECK_LICENSE = 'GET_CHECK_LICENSE';
export const GET_CHECK_LICENSE_SUCCESS = 'GET_CHECK_LICENSE_SUCCESS';
export const GET_TEAM_DETAIL = 'GET_TEAM_DETAIL';
export const GET_TEAM_DETAIL_SUCCESS = 'GET_TEAM_DETAIL_SUCCESS';
export const GET_TEAM_DEVICES = 'GET_TEAM_DEVICES';
export const GET_TEAM_DEVICES_SUCCESS = 'GET_TEAM_DEVICES_SUCCESS';
export const GET_DEVICE_BATCHES = 'GET_DEVICE_BATCHES';
export const GET_DEVICE_BATCHES_SUCCESS = 'GET_DEVICE_BATCHES_SUCCESS';
export const GET_BATCH_READINGS = 'GET_BATCH_READINGS';
export const GET_BATCH_READINGS_SUCCESS = 'GET_BATCH_READINGS_SUCCESS';
export const SET_CO2_GAUGE = 'SET_CO2_GAUGE';
export const SET_PRESSURE_GAUGE = 'SET_PRESSURE_GAUGE';
export const SET_TEMPERATURE_GAUGE = 'SET_TEMPERATURE_GAUGE';
export const SET_TEMPERATURE_F_GAUGE = 'SET_TEMPERATURE_F_GAUGE';
export const GET_TEAM_USERS = 'GET_TEAM_USERS';
export const GET_TEAM_USERS_SUCCESS = 'GET_TEAM_USERS_SUCCESS';
export const GET_TEAM_INVITES = 'GET_TEAM_INVITES';
export const GET_TEAM_INVITES_SUCCESS = 'GET_TEAM_INVITES_SUCCESS';
export const INVITE_TEAM_MEMBER = 'INVITE_TEAM_MEMBER';
export const CANCEL_INVITE = 'CANCEL_INVITE';
export const KICK_USER_FROM_TEAM = 'KICK_USER_FROM_TEAM';
export const AUTH_OPEN_DIALOG = 'AUTH_OPEN_DIALOG';
export const AUTH_CLOSE_DIALOG = 'AUTH_CLOSE_DIALOG';
export const AUTH_CANCEL_LOADING = 'AUTH_CANCEL_LOADING';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const SET_INTERVAL = 'SET_INTERVAL';
export const CLEAR_INTERVAL = 'CLEAR_INTERVAL';


 //Payment

export const GET_USER_PAYMENTS = 'GET_USER_PAYMENTS';
export const GET_USER_PAYMENTS_SUCCESS = 'GET_USER_PAYMENTS_SUCCESS';
export const GET_PAYMENT_DETAIL = 'GET_PAYMENT_DETAIL';
export const GET_PAYMENT_DETAIL_SUCCESS = 'GET_PAYMENT_DETAIL_SUCCESS';


// Admin
export const ADMIN_OPEN_DIALOG = 'ADMIN_OPEN_DIALOG';
export const ADMIN_CLOSE_DIALOG = 'ADMIN_CLOSE_DIALOG';
export const ADMIN_CANCEL_LOADING = 'ADMIN_CANCEL_LOADING';

export const ADMIN_GET_ALL_USERS = 'ADMIN_GET_ALL_USERS';
export const ADMIN_GET_ALL_USERS_SUCCESS = 'ADMIN_GET_ALL_USERS_SUCCESS';
export const ADMIN_CREATE_USER = 'ADMIN_CREATE_USER';
export const ADMIN_CREATE_USER_SUCCESS = 'ADMIN_CREATE_USER_SUCCESS';
export const ADMIN_EDIT_USER = 'ADMIN_EDIT_USER';
export const ADMIN_DELETE_USER = 'ADMIN_DELETE_USER';

export const ADMIN_GET_ALL_TEAMS = 'ADMIN_GET_ALL_TEAMS';
export const ADMIN_GET_ALL_TEAMS_SUCCESS = 'ADMIN_GET_ALL_TEAMS_SUCCESS';
export const ADMIN_CREATE_TEAM = 'ADMIN_CREATE_TEAM';
export const ADMIN_EDIT_TEAM = 'ADMIN_EDIT_TEAM';
export const ADMIN_DELETE_TEAM = 'ADMIN_DELETE_TEAM';

export const ADMIN_GET_ALL_PAYMENTS = 'ADMIN_GET_ALL_PAYMENTS';
export const ADMIN_GET_ALL_PAYMENTS_SUCCESS = 'ADMIN_GET_ALL_PAYMENTS_SUCCESS';

export const ADMIN_GET_ALL_TARIFFS = 'ADMIN_GET_ALL_TARIFFS';
export const ADMIN_GET_ALL_TARIFFS_SUCCESS = 'ADMIN_GET_ALL_TARIFFS_SUCCESS';
export const ADMIN_CREATE_TARIFF = 'ADMIN_CREATE_TARIFF';
export const ADMIN_EDIT_TARIFF = 'ADMIN_EDIT_TARIFF';
export const ADMIN_DELETE_TARIFF = 'ADMIN_DELETE_TARIFF';

export const ADMIN_GET_ALL_DEVICES = 'ADMIN_GET_ALL_DEVICES';
export const ADMIN_GET_ALL_DEVICES_SUCCESS = 'ADMIN_GET_ALL_DEVICES_SUCCESS';
export const ADMIN_GET_UNUSED_DEVICES = 'ADMIN_GET_UNUSED_DEVICES';
export const ADMIN_GET_UNUSED_DEVICES_SUCCESS = 'ADMIN_GET_UNUSED_DEVICES_SUCCESS';
export const ADMIN_GET_DEVICE_PRODUCT_TYPES = 'ADMIN_GET_DEVICE_PRODUCT_TYPES';
export const ADMIN_GET_DEVICE_PRODUCT_TYPES_SUCCESS = 'ADMIN_GET_DEVICE_PRODUCT_TYPES_SUCCESS';
export const ADMIN_CREATE_DEVICE = 'ADMIN_CREATE_DEVICE';
export const ADMIN_EDIT_DEVICE = 'ADMIN_EDIT_DEVICE';
export const ADMIN_DELETE_DEVICE = 'ADMIN_DELETE_DEVICE';

export const ADMIN_GET_ALL_LICENCES = 'ADMIN_GET_ALL_LICENCES';
export const ADMIN_GET_LICENCES = 'ADMIN_GET_LICENCES';
export const ADMIN_GET_ALL_LICENCES_SUCCESS = 'ADMIN_GET_ALL_LICENCES_SUCCESS';
export const ADMIN_GET_LICENCES_SUCCESS = 'ADMIN_GET_LICENCES_SUCCESS';
export const ADMIN_CREATE_LICENCE = 'ADMIN_CREATE_LICENCE';
export const ADMIN_EDIT_LICENCE = 'ADMIN_EDIT_LICENCE';
export const ADMIN_RENEW_LICENCE = 'ADMIN_RENEW_LICENCE';
export const ADMIN_DELETE_LICENCE = 'ADMIN_DELETE_LICENCE';
export const ADMIN_RESTORE_LICENCE = 'ADMIN_RESTORE_LICENCE';
export const ADMIN_GET_ALL_EXIST = 'ADMIN_GET_ALL_EXIST';