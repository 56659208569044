import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { message } from 'antd';
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  INIT,
  SIGNIN_USER_REFRESH,
  GET_USER_DETAILS,
  CHANGE_PASSWORD,
  SHOW_MESSAGE,
  FORGOT_PASSWORD,
  REQUEST_FORGOT_PASSWORD
} from 'constants/ActionTypes';
import {
  showAuthMessage,
  userSignInSuccess,
  userSignInRefreshSuccess,
  userSignOut,
  getUserDetails,
  getUserDetailsSuccess,
  changePasswordSuccess,
  authCancelLoading,
  userSignIn,
  forgotPasswordSuccess
} from '../actions/Auth';
import {
  loginByEmail,
  loginByRefresh,
  getCurrentUserDetails,
  changePasswordApi,
  requestResetPasswordApi,
  resetPasswordApi
} from '../../api';
import { history } from 'appRedux/store';

function* init() {
  //console.log('init');
  // history.push('/auth/login');
}

function* signInUserWithEmailPassword({ payload }) {
  const { email, password } = payload;
  const state = yield select();
  try {
    const res = yield call(loginByEmail, email, password);
    localStorage.setItem('refreshToken', res.refreshToken);
    yield all([put(userSignInSuccess(res)), put(getUserDetails())]);
    history.push(state.auth.initPath);
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* signInUserWithRefreshToken({ payload }) {
  const { refreshToken } = payload;
  const state = yield select();
  try {
    const res = yield call(loginByRefresh, refreshToken);
    yield all([put(userSignInRefreshSuccess(res)), put(getUserDetails())]);
    history.push(state.auth.initPath);
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(userSignOut())]);
  }
}

function* handleGetUserDetails() {
  try {
    const res = yield call(getCurrentUserDetails);
    yield put(getUserDetailsSuccess(res));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(userSignOut())]);
  }
}

function* handleChangePassword({ payload }) {
  const state = yield select();
  const { auth } = state;
  try {
    const res = yield call(changePasswordApi, {
      login: auth.dialogType === 'new' ? auth.loginEmail : auth.user.email,
      oldPassword: payload.oldPassword ? payload.oldPassword : auth.loginPassword,
      newPassword: payload.newPassword
    });
    yield put(changePasswordSuccess(payload.newPassword));
    if (auth.dialogType === 'new') yield put(userSignIn({ email: auth.loginEmail, password: payload.newPassword }));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(authCancelLoading())]);
  }
}

function* handleForgotPassword({ payload }) {
  const state = yield select();
  const { auth } = state;
  try {
    const res = yield call(resetPasswordApi, {
      login: auth.loginEmail,
      resetToken: payload.resetToken,
      newPassword: payload.newPassword
    });
    yield put(forgotPasswordSuccess(payload.newPassword));
    yield put(userSignIn({ email: auth.loginEmail, password: payload.newPassword }));
  } catch (err) {
    yield all([put(showAuthMessage(err.message)), put(authCancelLoading())]);
  }
}

function* handleRequestForgotPassword({ payload }) {
  try {
    yield call(requestResetPasswordApi, payload);
    message.success('Reset token has been sent!');
  } catch (err) {
    yield put(showAuthMessage(err.message));
  }
}

function* signOut() {
  localStorage.removeItem('refreshToken');
  history.push('/auth/login');
}

function* handleShowMessage({ payload }) {
  message.error(payload.toString());
}

export function* initSaga() {
  yield takeEvery(INIT, init);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signInUserRefresh() {
  yield takeEvery(SIGNIN_USER_REFRESH, signInUserWithRefreshToken);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* getUserDetailsSaga() {
  yield takeEvery(GET_USER_DETAILS, handleGetUserDetails);
}

export function* changePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD, handleChangePassword);
}

export function* showMessageSaga() {
  yield takeEvery(SHOW_MESSAGE, handleShowMessage);
}

export function* forgotPasswordSaga() {
  yield takeEvery(FORGOT_PASSWORD, handleForgotPassword);
}

export function* requestForgotPasswordSaga() {
  yield takeEvery(REQUEST_FORGOT_PASSWORD, handleRequestForgotPassword);
}

export default function* rootSaga() {
  yield all([
    fork(signInUser),
    fork(signOutUser),
    fork(signInUserRefresh),
    fork(initSaga),
    fork(getUserDetailsSaga),
    fork(changePasswordSaga),
    fork(showMessageSaga),
    fork(forgotPasswordSaga),
    fork(requestForgotPasswordSaga)
  ]);
}
