import { GET_USER_PAYMENTS_SUCCESS, GET_PAYMENT_DETAIL, GET_PAYMENT_DETAIL_SUCCESS } from 'constants/ActionTypes';

const INIT_STATE = {
  userPayments: [],
  currentPayment: {},
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_PAYMENTS_SUCCESS: {
      return {
        ...state,
        userPayments: action.payload,
      };
    }
    case GET_PAYMENT_DETAIL: {
      return {
        ...state,
        currentPayment: {},
      };
    }
    case GET_PAYMENT_DETAIL_SUCCESS: {
      return {
        ...state,
        currentPayment: action.payload,
      };
    }

    default:
      return state;
  }
};
